<template>
  <h1>
    {{ title }}
  </h1>
  <div id="gangs" class="container-fluid">
    <div class="row justify-content-center">
      <div v-for="gang in gangs" :key="gang.id" class="col-12">
        <div class="container-fluid ">
          <div class="row justify-content-center align-items-center">
            <div
              class="col-12 col-md-6 col-lg-5 transition-left "
              :class="gang.orderImg"
            >
              <img :src="gang.imgSrc" alt="" />
            </div>
            <div
              class="col-12 col-md-5 col-lg-5 info-block transition-right"
              :class="gang.orderText"
            >
              <h3>{{ gang.title }}</h3>
              <p>{{ gang.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventService from "../services/EventService";
export default {
  name: "Gangs",
  data() {
    return {
      gangs: null
    };
  },
  created() {
    // fetch event by id and set local data
    EventService.getGangs()
      .then(response => {
        this.gangs = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>

<style lang="scss" scoped>
h1 {
  background: #0438ad;
  color: #ffffff;
}
#gangs {
  .container-fluid {
    padding: 0;
    .row {
      img {
        max-width: 100%;
        width: 30rem;
        margin: 2em 0 0;
        animation: transition-left 3s;
      }
      .info-block {
        background: url("../assets/splat-1.svg") no-repeat center;
        background-size: cover;
        padding: 1.5em;
        margin: 2em 0;
        box-shadow: 5px 5px rgba(98, 2, 2, 0.4), 10px 10px rgba(98, 2, 2, 0.3),
          15px 15px rgba(98, 2, 2, 0.2), 20px 20px rgba(98, 2, 2, 0.1),
          25px 25px rgba(98, 2, 2, 0.05);
        transition: ease-in-out 1s;
        border: 1px solid #f9f7f7;
        animation: transition-right 3s;

        &:hover,
        &:active {
          box-shadow: 5px 5px rgba(0, 98, 90, 0.4),
            10px 10px rgba(0, 98, 90, 0.3), 15px 15px rgba(0, 98, 90, 0.2),
            20px 20px rgba(0, 98, 90, 0.1), 25px 25px rgba(0, 98, 90, 0.05);
        }

        h3 {
          color: #054586;
          font-weight: 900;
          text-align: left;
        }

        p {
          text-align: left;
          margin: 0;
        }
      }
    }
  }
}
@keyframes transition-left {
  from {
    opacity: 0;
    transform: translateX(-105%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes transition-right {
  from {
    opacity: 0;
    transform: translateX(105%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
