<template>
  <gangs />
</template>
<script>
import Gangs from "../components/Gangs";
export default {
  components: {
    Gangs
  }
};
</script>
<style lang="scss" scoped></style>
